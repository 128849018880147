import React from "react";
import { Container, Row, Col } from "react-bootstrap"
import { Layout } from "../components/Layout";

export default function NotFound() {
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            Page not found!
            <span role="img" aria-label="crying face">
              😢
            </span>
            <p>It looks like that page doesn't exist</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}